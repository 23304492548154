@import '../../../../scss/theme-bootstrap';

body {
  .site-header-formatter__logo {
    .icon--logo {
      // We keep the logo in place for size attributes.
      // just add the background for visible animation
      opacity: 0;
    }
    background: var(--logo-url) no-repeat center;
    background-size: contain;
    // we need to force to CMS values whenever the template is used.
    /* stylelint-disable declaration-no-important */
    height: var(--logo-height) !important;
    top: var(--logo-top-position) !important;
    @include breakpoint($bp--medium-up) {
      height: var(--logo-height--pc) !important;
      top: var(--logo-top-position--pc) !important;
    }
    /* stylelint-enable declaration-no-important */
  }
}
